/* istanbul ignore file */
import { APP_ROOT_ELEMENT_ID } from './constants';

async function init() {
  const rootElement = document.getElementById(APP_ROOT_ELEMENT_ID);

  if (!rootElement) {
    throw new Error(`Element #${APP_ROOT_ELEMENT_ID} was not found!`);
  }

  const appRender = await import('./appRender');

  void appRender.render(rootElement);
}

init().catch((error) => {
  throw error;
});
