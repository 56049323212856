export const TEAM_NAME = 'Frontend Platform';
export const APP_NAME = 'ui-prototype';
export const PACKAGE_NAME = '@ori/ui-prototype';

export const EMOTION_CACHE_KEY = `${APP_NAME}-emotion-cache-key`;

export const APP_ROOT_ELEMENT_ID = `${APP_NAME}-root`;

export * from './config';
export * from './pages';
